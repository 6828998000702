import React from 'react'
import { Card } from 'components'
import { Grid, Typography } from '@mui/material'
import { formatCurrency } from 'helpers/utils'
import styles from './home.module.scss'
export const RevshareEarnings = ({ revshareOverview }) => {
  const style = {
    borderRight: ' 3px solid #BBBBBB',
    backgroundColor: 'white !important',
    '& .MuiCardContent-root:last-child': {
      pb: 1
    },
    '& .MuiCardHeader-root': {
      pb: 0,
      fontWeight: 280
    }
  }
  return (
    <Grid container columnSpacing={7}>
      <Grid item xs={12} md={6}>
        <Card style={style} title={'Monthly Earnings'}>
          <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>
            {revshareOverview?.monthlyEarning === 'N/A'
              ? revshareOverview?.monthlyEarning
              : formatCurrency(revshareOverview?.monthlyEarning) || 0}
          </Typography>
          <Typography className={styles.revshareTime}>
            {revshareOverview?.endMonthMessage || '.................'}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} mt={{ xs: 2, md: 0 }}>
        <Card style={style} title={'Annual Earnings'}>
          <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>
            {revshareOverview?.annualEarning === 'N/A'
              ? revshareOverview?.annualEarning
              : formatCurrency(revshareOverview?.annualEarning || 0)}
          </Typography>
          <Typography className={styles.revshareTime}>
            {revshareOverview?.monthRangeMessage || '.................'}
          </Typography>
        </Card>
      </Grid>
    </Grid>
  )
}
