const { gql } = require('@apollo/client')

const GET_LISTING = gql`
  query getListing($id: String!) {
    getListing(id: $id) {
      id
      public_id
      address
      type
      status
      option
      notes
      admin_notes
      created
      creator_id
      need_feature_sheet_and_social_media_post
      mls_pictures_link
      hi_res_pictures_link
      agent_note
      additional_copy_note
      need_sale_sign
      sign_installation_date
      isListingAttached
      documents {
        id
        name
        path
        created
        checklist_document_type
        document_id
        new_filename
      }
      additionalDocuments {
        id
        type
        size
        key
        fileName
        newFilename
      }
      adminDocuments {
        id
        type
        size
        key
        fileName
        newFilename
      }
      checkedDocuments {
        key
        isChecked
      }
      agents {
        id
        name
        thumbnail
        email
        deleted_at
      }
      comments {
        id
        created
        message
        sender {
          id
          name
          thumbnail
        }
      }
      conditions {
        id
        conditional_upon
        conditional_until
      }
      conditional
      upload_conditional
      is_rider_needed
      checkListTasks {
        id
        name
        isChecked
      }
    }
  }
`

const GET_LISTING_BY_ID = gql`
  query getListingById($id: Int!) {
    getListingById(id: $id) {
      id
      address
      type
      option
    }
  }
`

const GET_AGENTS = gql`
  query getAgents {
    getAgents {
      id
      agentnum
      public_id
      name
      email
      picture
      thumbnail
      created
      role
    }
  }
`

const GET_ALL_USERS = gql`
  query getUsersV2($excludeDeleted: Boolean!)  {
    getUsersV2(excludeDeleted: $excludeDeleted)  {
      id
      agentnum
      public_id
      name
      email
      picture
      thumbnail
      created
      role
      deleted_at
    }
  }
`

const GET_LISTINGS = gql`
  query getListings($filters: ListingFilterInput!) {
    getListings(filters: $filters) {
      id
      public_id
      address
      type
      status
      status_pretty_name
      option
      created
      creator_id
      deal_count
      documents {
        id
        name
        path
        created
        checklist_document_type
        document_id
        type
        size
        new_filename
      }
      agents {
        id
        name
        thumbnail
      }
      linked_deal
      checklist_tasks_completed
      isListingAttached
    }
  }
`

const GET_DEALS = gql`
  query getDeals($filters: DealsFilterInput!) {
    getDeals(filters: $filters) {
      id
      public_id
      type
      sale_price
      price
      close_date
      settled_date
      status
      status_pretty_name
      creator_id
      agents {
        id
        name
        thumbnail
      }
      address
      listing {
        id
        type
        option
        address
      }
      created
      checklist_tasks_completed
    }
  }
`

const GET_LIBRARY = gql`
  query getLibrary($filters: LibrarySearch) {
    getLibrary(filters: $filters)
  }
`

const GET_FOLDER = gql`
  query getLibraryDocuments($prefix: String) {
    getLibraryDocuments(prefix: $prefix)
  }
`

const GET_ROOT_FOLDERS = gql`
  query getRootFolders($prefix: String) {
    getRootFolders(prefix: $prefix)
  }
`

const GET_LIBRARY_FOLDERS = gql`
  query getLibraryFolders($folderId: String, $filters: LibraryFilters) {
    getLibraryFolders(folderId: $folderId, filters: $filters) {
      id
      public_id
      owner {
        id
        name
      }
      name
      section {
        id
        name
      }
    }
  }
`

const GET_LIBRARY_DOCUMENTS = gql`
  query getLibraryDocuments($folderId: String, $filters: LibraryFilters) {
    getLibraryDocuments(folderId: $folderId, filters: $filters) {
      id
      public_id
      name
      path
      type
      size
      owner {
        id
        name
      }
      section {
        id
        name
      }
    }
  }
`

const GET_REVSHARE = gql`
  query getRevshare($userId: String) {
    getRevshare(userId: $userId) {
      id
      user {
        id
        name
        thumbnail
      }
      monthly
      annual
      total
      referrals {
        id
        user {
          id
          name
          thumbnail
        }
        monthly
        annual
        total
        referrals {
          id
          user {
            id
            name
            thumbnail
          }
          monthly
          annual
          total
          referrals {
            id
            user {
              id
              name
              thumbnail
            }
            monthly
            annual
            total
            referrals {
              id
              user {
                id
                name
                thumbnail
              }
              monthly
              annual
              total
              referrals {
                id
              }
            }
          }
        }
      }
    }
  }
`

const GET_DEAL = gql`
  query getDeal($id: String!) {
    getDeal(id: $id) {
      id
      public_id
      brokerage_side
      double_ended
      representative_type
      type
      status
      sale_price
      lease_price
      deposit_amount
      second_deposit_amount
      offer_date
      acceptance_date
      close_date
      settled_date
      notes
      conditional
      conditions {
        id
        upon
        until
      }
      brokerage
      brokerage_phone_number
      brokerage_email
      paperwork_to_lawyer
      annual_personal_deal
      listing_brokerage_commission
      cooperating_brokerage_commission
      total_commission
      total
      first_installment
      second_installment
      third_installment
      final_installment
      total_precon_commission
      creator_id
      agents {
        id
        name
        thumbnail
        email
        deleted_at
      }
      listing {
        id
        type
        option
        address
      }
      address
      documents {
        id
        name
        path
        created
        checklist_document_type
        document_id
        new_filename
      }
      comments {
        id
        created
        message
        sender {
          id
          name
          thumbnail
        }
      }
      referral {
        id
        first_name
        last_name
        referral_amount
        referral_percentage
        office_name
        office_email
        office_address
        office_phone
        office_fax
      }
      buyer_lawyer {
        id
        name
        address
        phone_number
        fax_number
        email
      }
      seller_lawyer {
        id
        name
        address
        phone_number
        fax_number
        email
      }
      external_agents {
        id
        name
        email
        phone
      }
      created
      admin_notes
      additionalDocuments {
        id
        type
        size
        key
        fileName
        newFilename
      }
      adminDocuments {
        id
        type
        size
        key
        fileName
        newFilename
      }
      checkedDocuments {
        key
        isChecked
      }
      agents_commission_breakdown {
        primary_agent_commission {
          id
          commission_amount
          commission_percentage
          name
        }
        secondary_agents_commission {
          id
          commission_amount
          commission_percentage
          name
        }
      }
      deal_commission_amount
      deal_commission_percentage
      checkListTasks {
        id
        name
        isChecked
      }
    }
  }
`

const GET_FORUM_TOPICS = gql`
  query getForumTopics {
    getForumTopics {
      id
      author
      topic
      last_updated
      author_name
    }
  }
`

const GET_USER_PROFILE = gql`
  query getUser {
    getUser {
      id
      name
      email
      alternative_email
      address
      first_name
      last_name
      title
      date_of_birth
      phone_number
      picture
      treb_number
      sin_number
      hst_number
      emergency_contact_name
      emergency_contact_phone
      emergency_contact_relationship
    }
  }
`

const GET_USER_PROFILE_BY_ID = gql`
  mutation getUserById($userId: Int!) {
    getUserById(userId: $userId) {
      id
      name
      email
      alternative_email
      address
      first_name
      last_name
      title
      date_of_birth
      phone_number
      picture
      treb_number
      sin_number
      hst_number
      emergency_contact_name
      emergency_contact_phone
      emergency_contact_relationship
      role
      date_incorporated
      name_incorporated
      reco_expiry
      reco_number
      date_joined_rare
    }
  }
`

const GET_ANNOUNCEMENTS = gql`
  query Data($filters: AnnouncementFilterInput) {
    getAnnouncements(filters: $filters) {
      totalCount
      data {
        id
        start_date
        end_date
        admin_pinned
        content
        created
        edited
        is_read
        is_pinned
        is_starred
        public_id
        status
        title
        view_count
        sender_id
        sender
        attachments {
          id
          path
          file_name
          file_size
          new_filename
        }
      }
    }
  }
`

const GET_ANNOUNCEMENT = gql`
  query getAnnouncement($filters: AnnouncementFilterInput!) {
    getAnnouncement(filters: $filters) {
      id
      public_id
      attachments {
        id
        path
        file_name
        new_filename
        file_size
        source_path
      }
      view_count
      status
      is_pinned
      admin_pinned
      start_date
      end_date
      is_read
      is_starred
      title
      content
      created
      sender_id
      sender
    }
  }
`
const GET_GLOBAL_SEARCH = gql`
  query getGlobalSearch($filters: SearchFilterInput) {
    getGlobalSearch(filters: $filters) {
      listings {
        id
        public_id
        listing_type
        listing_option
        property_address
        deal_status_name
        deal_status_pretty_name
        isColumn
      }
      deals {
        id
        public_id
        property_address
        deal_status_name
        deal_status_pretty_name
        deal_type_name
        deal_type_pretty_name
        isColumn
      }
    }
  }
`
const GET_ACTIVITY_LOG = gql`
  query GetActivityLogs {
    getActivityLogs {
      activity
      activity_time
      description
      user_id
      user {
        id
        name
        thumbnail
      }
    }
  }
`
const GET_DOWNLOADS_LOGS = gql`
  query getDownloadLog {
    getDownloadLog {
      activity
      activity_time
      file_name
      file_size
      user {
        id
        name
        thumbnail
      }
    }
  }
`
const GET_REVSHARE_OVERVIEW_DETAILS = gql`
  query getDashBoardRevshareDetails($email: String) {
    getDashBoardRevshareDetails(email: $email)
  }
`

const Get_Announcement_Senders = gql`
  query getAnnouncementSenders {
    getAnnouncementSenders {
      id
      sender
    }
  }
`
const GET_ROLES = gql`
  query getRoles {
    getRoles {
      id
      name
      pretty_name
    }
  }
`
export {
  GET_LISTING,
  GET_AGENTS,
  GET_ALL_USERS,
  GET_LISTINGS,
  GET_DEALS,
  GET_LIBRARY,
  GET_LIBRARY_FOLDERS,
  GET_LIBRARY_DOCUMENTS,
  GET_REVSHARE,
  GET_DEAL,
  GET_FORUM_TOPICS,
  GET_LISTING_BY_ID,
  GET_USER_PROFILE,
  GET_ANNOUNCEMENTS,
  GET_GLOBAL_SEARCH,
  GET_ANNOUNCEMENT,
  GET_ACTIVITY_LOG,
  GET_ROOT_FOLDERS,
  GET_DOWNLOADS_LOGS,
  GET_FOLDER,
  GET_REVSHARE_OVERVIEW_DETAILS,
  Get_Announcement_Senders,
  GET_USER_PROFILE_BY_ID,
  GET_ROLES
}
