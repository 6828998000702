import React, { useState } from 'react'
import MUIDataTable from 'mui-datatables'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableCell,
  TableFooter,
  TableRow,
  ThemeProvider,
  createTheme,
  useMediaQuery
} from '@mui/material'
import Locked from '../../assets/images/locked.png'
import Unlocked from '../../assets/images/unlocked.png'
import styles from './revshare.module.scss'
import { RevShareTableTheme } from 'components/table/BasicTableTheme'
import { Avatar, Row, Tag, Typography } from 'components'
import { formatCurrency, getFileUrl } from 'helpers/utils'

const RevShareTable = ({
  data,
  tierNum,
  isTierLock = false,
  length = 0,
  totalMEE = 0,
  totalMPE = 0,
  totalYEE = 0,
  totalYPE = 0,
  isMidMonth,
  month,
  message
}) => {
  const [expanded, setExpanded] = useState(false)
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const handleChange = () => {
    setExpanded(length === 0 ? false : expanded ? false : true)
  }

  const columns = [
    {
      name: 'agentData',
      label: 'Agent Name',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Row gap={12}>
              {!isMobile && <Avatar name={value?.name} src={getFileUrl('')} />}
              <Typography.Body
                className={styles.header}
                type={2}
                style={{ color: isTierLock ? '#dd0000' : '', fontWeight: 600, lineHeight: '40px' }}>
                {value?.name}
              </Typography.Body>
            </Row>
          )
        }
      }
    },
    {
      name: 'revShareStatus',
      label: 'RevShare Status',
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Tag className={styles.header} style={{ color: '#007605' }} text={'Eligible'} background={'#ceffc8'} />
          ) : (
            <Tag className={styles.header} style={{ color: '#007605' }} text={'Not Eligible'} background={'#f2cdff'} />
          )
        }
      }
    },
    {
      name: 'monthlyRevShare',
      label: `${month} Earning`,
      options: {
        customBodyRender: (value) => {
          return (
            <Typography.Body className={styles.header} style={{ color: isTierLock ? '#dd0000' : '' }}>
              {isTierLock ? formatCurrency(value?.monthPotentialEarning) : formatCurrency(value?.monthEligibleEarning)}
            </Typography.Body>
          )
        }
      }
    },
    {
      name: 'yearlyRevShare',
      label: 'YTD',
      options: {
        customBodyRender: (value) => {
          return (
            <Typography.Body className={styles.header} style={{ color: isTierLock ? '#dd0000' : '' }}>
              {isTierLock ? formatCurrency(value?.yearPotentialEarning) : formatCurrency(value?.yearEligibleEarning)}
            </Typography.Body>
          )
        }
      }
    }
  ]

  const options = {
    filter: false,
    search: false,
    print: false,
    sort: false,
    download: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive: 'standard',
    pagination: true,
    rowsPerPageOptions: [100, 50, 25, 10],
    rowsPerPage: 100,
    tableBodyMaxHeight: '100%',
    customTableBodyFooterRender: () => {
      return (
        <TableFooter>
          <TableRow>
            <TableCell></TableCell>
            <TableCell
              className={styles.header}
              sx={{ fontSize: '16px', fontWeight: 700, color: isTierLock ? '#dd0000' : 'black' }}>
              TIER TOTAL :
            </TableCell>
            <TableCell
              className={styles.header}
              sx={{ fontSize: '16px', fontWeight: 700, color: isTierLock ? '#dd0000' : 'black' }}>
              {isTierLock ? formatCurrency(totalMPE) : formatCurrency(totalMEE)}
            </TableCell>
            <TableCell
              className={styles.header}
              sx={{ fontSize: '16px', fontWeight: 700, color: isTierLock ? '#dd0000' : 'black' }}>
              {isTierLock ? formatCurrency(totalYPE) : formatCurrency(totalYEE)}
            </TableCell>
          </TableRow>
        </TableFooter>
      )
    }
  }

  const renderTitle = () => {
    if (message) {
      return (
        <Typography.Heading className={!isTierLock ? styles.unlockedTitle : styles.lockedTitle}>
          {message}
        </Typography.Heading>
      )
    } else if (isMidMonth) {
      return <Typography.Heading className={styles.unlockedTitle}>{message}</Typography.Heading>
    }
  }

  return (
    <div className={styles.tableContainer}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          className={styles.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          sx={{
            m: 0,
            '& .MuiAccordionSummary-content': {
              margin: 0,
              '& .Mui-expanded ': {
                margin: 0
              }
            }
          }}>
          <Row gap={10} className={styles.title}>
            {isTierLock ? (
              <img src={Locked} alt="Locked" className={styles.lockIcon} />
            ) : (
              <img src={Unlocked} alt="Unlocked" className={styles.lockIcon} />
            )}
            <Typography.Heading className={styles.tierNum}>
              Tier {tierNum} ({length})
            </Typography.Heading>
            {renderTitle()}
          </Row>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, pt: 1 }}>
          {expanded && (
            <ThemeProvider theme={RevShareTableTheme()}>
              <MUIDataTable data={data} columns={columns} options={options} />
            </ThemeProvider>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default RevShareTable
