import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { AddressAutoComplete, Loading, Select } from 'components'
import { GET_LISTINGS } from 'helpers/gql'
import { Controller, useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { FORM_DEAL_TYPES, FORM_BROKERAGE_SIDES, LISTING_COMMON_DOCUMENTS } from 'helpers/constants'
import { DealContext, UserContext } from 'context'
import { useEffect, useMemo } from 'react'

const PropertyInformation = ({ isAgent = false, isDoubleEnded = '', bothDealType = '' }) => {
  const { user } = useContext(UserContext)
  const { control, getValues, resetField } = useFormContext()
  const { type, brokerage_side, double_ended, representative_type } = getValues()
  const {
    handleAgentByListing,
    handleDocumentsByListing,
    documentsByListing,
    isEdit,
    deal,
    disabled = false,
    isView
  } = useContext(DealContext)
  const dealType = type ?? deal?.type

  const selectListing =
    [FORM_DEAL_TYPES.SALE, FORM_DEAL_TYPES.LEASE].includes(dealType) &&
    [FORM_BROKERAGE_SIDES.LISTING].includes(brokerage_side)
  const canShowListing =
    selectListing ||
    bothDealType === 'seller' ||
    isDoubleEnded === 'yes' ||
    double_ended === 'yes' ||
    representative_type === 'seller'

  const isBuyerBothDealType =
    brokerage_side === FORM_BROKERAGE_SIDES.BOTH &&
    (bothDealType === 'buyer' || representative_type === 'buyer') &&
    isDoubleEnded !== 'yes'
  const isCoop = brokerage_side === FORM_BROKERAGE_SIDES.CO_OP
  const isPreCon = dealType === FORM_DEAL_TYPES.PRE_CON
  const canShowPropertyAddress = isBuyerBothDealType || isCoop || isPreCon

  const {
    data: { getListings: listings } = [],
    loading: listingsLoading,
    refetch
  } = useQuery(
    GET_LISTINGS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        filters: { status: 'approved' }
      },
      skip: !canShowListing
    },
    {
      onError: (err) => {
        toast.dismiss()
        if (!err?.message?.includes('Session timeout')) {
          toast.error(err.message)
        }
      }
    }
  )

  useEffect(() => {
    refetch()
  }, [refetch])

  const listingOptions = useMemo(() => {
    const filteredListings = listings?.filter((listing) => listing?.linked_deal === null)
    return filteredListings?.map((listing) => {
      return {
        value: listing.id,
        label: listing.address
      }
    })
  }, [listings])

  const handleChangeListing = (e) => {
    const isDealSaleORLease = [FORM_DEAL_TYPES.SALE, FORM_DEAL_TYPES.LEASE].includes(dealType)

    if (isDealSaleORLease && documentsByListing) {
      const keys = Object.keys(LISTING_COMMON_DOCUMENTS)
      keys
        .filter((key) => key.includes(getValues().type))
        .forEach((key) => {
          resetField(key)
        })
    }
    listings.filter((listing) => {
      if (listing.id === e.value) {
        const filteredAgents = listing.agents?.filter((agent) => agent.id !== user.id)
        handleAgentByListing(filteredAgents)
        handleDocumentsByListing(listing.documents)
      }
    })
  }

  if (listingsLoading) return <Loading fullpage />

  return (
    <>
      {canShowListing && (
        <Controller
          name="listing_id"
          control={control}
          defaultValue={isEdit || isView ? { value: deal?.listing?.id, label: deal?.listing?.address } : undefined}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              name="listing_id"
              onChange={(e) => {
                onChange(e)
                handleChangeListing(e)
              }}
              value={value}
              type={5}
              placeholder="Select a listing"
              label="Select your listing"
              options={listingOptions ?? []}
              error={error?.message}
              disabled={isEdit ? true : false}
              required
            />
          )}
        />
      )}

      {canShowPropertyAddress && (
        <Controller
          name="property_address"
          control={control}
          defaultValue={deal?.listing ? '' : deal?.address || ''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <AddressAutoComplete
              value={value}
              required
              name="address"
              onChange={onChange}
              error={error?.message}
              disabled={isEdit ? isEdit : disabled}
            />
          )}
        />
      )}
    </>
  )
}
export default PropertyInformation
