import * as Yup from 'yup'
import moment from 'moment'
import { STATUS_TYPES } from 'helpers/constants'

const nameRegex = /^[A-Za-z ]*$/
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
const phoneRegex = /^(1-)?\d{3}-\d{3}-\d{4}$/

export class DealHelper {
  static editDealValidationSchema = (type, brokerage_side, status) => {
    return Yup.object().shape({
      brokerage_side: Yup.string().when('type', {
        is: (type) => type !== 'pre-con',
        then: () => Yup.string().required('Deal type is required')
      }),
      listing_id: Yup.mixed().when(['brokerage_side', 'representative_type', 'type'], {
        is: (brokerage_side, representative_type, type) =>
          brokerage_side !== 'co-op' && representative_type !== 'buyer' && type !== 'pre-con',
        then: () => Yup.mixed().required('Listing is required')
      }),
      property_address: Yup.string().when(['brokerage_side', 'representative_type', 'type'], {
        is: (brokerage_side, representative_type, type) =>
          brokerage_side === 'co-op' || representative_type === 'buyer' || type === 'pre-con',
        then: () =>
          Yup.string()
            .max(190, 'Property Address  must be atmost 190 Characters')
            .required('Property address is required')
      }),
      double_ended: Yup.string()
        .nullable()
        .when('brokerage_side', {
          is: (value) => value === 'both',
          then: () => Yup.string().required('Double ended deal is required')
        }),
      representative_type: Yup.string()
        .nullable()
        .when('double_ended', {
          is: (value) => value === 'no',
          then: () => Yup.string().required('Representative type is required')
        }),
      offer_date: Yup.mixed()
        .nullable()
        .typeError('Invalid Date')
        .when('type', {
          is: (type) => type !== 'pre-con',
          then: () =>
            Yup.date()
              .typeError('Invalid Date')
              .required('Offer date is required')
              .max(Yup.ref('close_date'), 'Offer date should be less than close date')
        }),
      acceptance_date: Yup.date()
        .typeError('Invalid Date')
        .when('type', {
          is: (type) => type !== 'pre-con',
          then: () =>
            Yup.date()
              .nullable()
              .required('Firm date is required')
              .min(Yup.ref('offer_date'), 'Firm date should be greater than offer date')
              .max(Yup.ref('close_date'), 'Firm date should be less than close date'),
          otherwise: () =>
            Yup.date()
              .nullable()
              .required('Firm date is required')
              .test('validateDate', 'Firm date should be less than close date.', (value, ctx) => {
                if (moment(ctx.parent.close_date).year() >= moment(value).year()) {
                  return true
                } else {
                  return false
                }
              })
        }),
      close_date: Yup.date()
        .typeError('Invalid Date')
        .when('type', {
          is: (type) => type !== 'pre-con',
          then: () =>
            Yup.date()
              .nullable()
              .required('Close date is required')
              .min(Yup.ref('offer_date'), 'Close date should be greater than offer date'),
          otherwise: () =>
            Yup.date()
              .nullable()
              .required('Close date is required')
              .test('validateDate', 'Close date should be greater than firm date.', (value, ctx) => {
                if (moment(ctx.parent.acceptance_date).year() <= moment(value).year()) {
                  return true
                } else {
                  return false
                }
              })
        }),
      settled_date: Yup.mixed()
        .nullable()
        .typeError('Invalid Date')
        .when('status', {
          is: (status) => status === STATUS_TYPES.settled,
          then: () =>
            Yup.date()
              .nullable()
              .typeError('Invalid Date')
              .required('Settle date is required')
              .min(Yup.ref('close_date'), 'Settle date should be greater than close date'),
          otherwise: () => Yup.date().nullable()
        }),
      sale_price: Yup.mixed()
        .nullable()
        .when(['type'], {
          is: (type) => type === 'pre-con' || type === 'sale',
          then: () =>
            Yup.number()
              .required('Sale price is required')
              .test('is-not-zero', 'Sale price must be greater than 0', (value) => {
                if (!value) return true
                const parsedValue = parseFloat(value)
                return parsedValue !== 0
              })
              .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        }),
      lease_price: Yup.mixed()
        .nullable()
        .when(['type'], {
          is: (value) => value === 'lease',
          then: () =>
            Yup.number()
              .required('Lease price is required')
              .test('is-not-zero', 'Lease price must be greater than 0', (value) => {
                if (!value) return true
                const parsedValue = parseFloat(value)
                return parsedValue !== 0
              })
              .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        }),
      deposit_amount: Yup.number()
        .nullable()
        .when(['type'], {
          is: (type) => type !== 'pre-con',
          then: () =>
            Yup.number()
              .transform((value, originalValue) => (originalValue === '' ? undefined : value))
              .required('Deposit amount is required')
        }),
      second_deposit_amount: Yup.number()
        .nullable()
        .transform((value, originalValue) => (originalValue === '' ? undefined : value)),
      /* NOTE: we can use this in feature
      sale_all_document: Yup.mixed().nullable(),
      sale_agreement_purchase_sale: Yup.mixed()
        .nullable()
        .when(['sale_all_document', 'type'], {
          is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
          then: () => Yup.mixed().required('Agreement of Purchase and Sale is required')
        }),
      sale_confirmation_cooperating_representative: Yup.mixed()
        .nullable()
        .when(['sale_all_document', 'type'], {
          is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
          then: () => Yup.mixed().required('Confirmation of Cooperation is required')
        }),
      sale_working_with_realtor: Yup.mixed()
        .nullable()
        .when(['sale_all_document', 'type'], {
          is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
          then: () => Yup.mixed().required('Working with a Realtor is required')
        }),
      sale_fintrac_form_630: Yup.mixed()
        .nullable()
        .when(['sale_all_document', 'type'], {
          is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
          then: () =>
            Yup.mixed().required('Fintrac Form 630  - Individual Identification Information Record is required')
        }),
      sale_fintrac_form_634: Yup.mixed()
        .nullable()
        .when(['sale_all_document', 'type'], {
          is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
          then: () =>
            Yup.mixed().required(
              'Fintrac Form 634 - Politically Exposed Person/Head of International Organization Checklist is required'
            )
        }),
      sale_copy_deposit_receipt: Yup.mixed()
        .nullable()
        .when(['sale_all_document', 'type'], {
          is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
          then: () => Yup.mixed().required('Copy of Deposit Receipt is required')
        }),
         */

      // Condition for required docs for listing and both
      /* 
      sale_listing_agreement: Yup.mixed()
        .nullable()
        .when(['sale_all_document', 'brokerage_side', 'type', 'representative_type'], {
          is: (sale_all_document, brokerage_side, type, representative_type) =>
            (sale_all_document === null || typeof sale_all_document === 'undefined') &&
            brokerage_side !== 'co-op' &&
            representative_type !== 'buyer' &&
            type === 'sale',
          then: () => Yup.mixed().required('Listing Agreement is required')
        }),
      sale_data_sheet: Yup.mixed()
        .nullable()
        .when(['sale_all_document', 'brokerage_side', 'type', 'representative_type'], {
          is: (sale_all_document, brokerage_side, type, representative_type) =>
            (sale_all_document === null || typeof sale_all_document === 'undefined') &&
            brokerage_side !== 'co-op' &&
            representative_type !== 'buyer' &&
            type === 'sale',
          then: () => Yup.mixed().required('Data Sheet is required')
        }),
 */
      // Condition for required docs for co-op and both
      /*  
      sale_form_127: Yup.mixed()
        .nullable()
        .when(['sale_all_document', 'brokerage_side', 'type', 'representative_type'], {
          is: (sale_all_document, brokerage_side, type, representative_type) =>
            (sale_all_document === null || typeof sale_all_document === 'undefined') &&
            brokerage_side !== 'listing' &&
            representative_type !== 'seller' &&
            type === 'sale',
          then: () => Yup.mixed().required('Form 127 – Condition(s) In Offer – Buyer Acknowledgment is required')
        }),
      sale_fintrac_form_635: Yup.mixed()
        .nullable()
        .when(['sale_all_document', 'brokerage_side', 'type', 'representative_type'], {
          is: (sale_all_document, brokerage_side, type, representative_type) =>
            (sale_all_document === null || typeof sale_all_document === 'undefined') &&
            brokerage_side !== 'listing' &&
            representative_type !== 'seller' &&
            type === 'sale',
          then: () => Yup.mixed().required('Fintrac Form 635 – Receipt of Funds is required')
        }),
      sale_buyer_rep_agreement: Yup.mixed()
        .nullable()
        .when(['sale_all_document', 'brokerage_side', 'type', 'representative_type'], {
          is: (sale_all_document, brokerage_side, type, representative_type) =>
            (sale_all_document === null || typeof sale_all_document === 'undefined') &&
            brokerage_side !== 'listing' &&
            representative_type !== 'seller' &&
            type === 'sale',
          then: () => Yup.mixed().required('Buyer Representation Agreement is required')
        }), */

      //Pre-con deal documents validation
      /*  
      new_sale_all_document: Yup.mixed().nullable(),
      new_sale_first_page_agreement_purchase: Yup.mixed()
        .nullable()
        .when(['new_sale_all_document', 'type'], {
          is: (new_sale_all_document, type) =>
            (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
          then: () => Yup.mixed().required('1st page of Agreement of Purchase and Sale and all amendments is required')
        }),
      new_sale_broker_coop_letter: Yup.mixed()
        .nullable()
        .when(['new_sale_all_document', 'type'], {
          is: (new_sale_all_document, type) =>
            (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
          then: () => Yup.mixed().required('Broker Co-op Letter is required')
        }),
      new_sale_first_page_tarion: Yup.mixed()
        .nullable()
        .when(['new_sale_all_document', 'type'], {
          is: (new_sale_all_document, type) =>
            (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
          then: () => Yup.mixed().required('1st page of Tarion is required')
        }),
      new_sale_working_with_realtor: Yup.mixed()
        .nullable()
        .when(['new_sale_all_document', 'type'], {
          is: (new_sale_all_document, type) =>
            (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
          then: () => Yup.mixed().required('Working with a Realtor is required')
        }),
      new_sale_buyer_rep_agreement: Yup.mixed()
        .nullable()
        .when(['new_sale_all_document', 'type'], {
          is: (new_sale_all_document, type) =>
            (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
          then: () => Yup.mixed().required('Buyer Representation Agreement is required')
        }),
      mortgage_preapproval: Yup.mixed()
        .nullable()
        .when(['new_sale_all_document', 'type'], {
          is: (new_sale_all_document, type) =>
            (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
          then: () => Yup.mixed().required('Mortgage Pre-Approvals is required')
        }),
      new_sale_copy_deposit_receipt: Yup.mixed()
        .nullable()
        .when(['new_sale_all_document', 'type'], {
          is: (new_sale_all_document, type) =>
            (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
          then: () => Yup.mixed().required('Copy of Deposits is required')
        }),
         */

      //Lease deal documents validation
      /* 
      lease_all_document: Yup.mixed().nullable(),
      lease_agreement_lease: Yup.mixed()
        .nullable()
        .when(['lease_all_document', 'type'], {
          is: (lease_all_document, type) =>
            (lease_all_document === null || typeof lease_all_document === 'undefined') && type === 'lease',
          then: () => Yup.mixed().required('Agreement to Lease is required')
        }),
      lease_ontario_standard_lease: Yup.mixed()
        .nullable()
        .when(['lease_all_document', 'type'], {
          is: (lease_all_document, type) =>
            (lease_all_document === null || typeof lease_all_document === 'undefined') && type === 'lease',
          then: () => Yup.mixed().required('Ontario Standard Lease Agreement is required')
        }),
      lease_confirmation_cooperating_representative: Yup.mixed()
        .nullable()
        .when(['lease_all_document', 'type'], {
          is: (lease_all_document, type) =>
            (lease_all_document === null || typeof lease_all_document === 'undefined') && type === 'lease',
          then: () => Yup.mixed().required('Confirmation of Cooperation is required')
        }),
      lease_listing_agreement: Yup.mixed()
        .nullable()
        .when(['lease_all_document', 'type', 'brokerage_side', 'representative_type'], {
          is: (lease_all_document, type, brokerage_side, representative_type) =>
            (lease_all_document === null || typeof lease_all_document === 'undefined') &&
            type === 'lease' &&
            brokerage_side !== 'co-op' &&
            representative_type !== 'buyer',
          then: () => Yup.mixed().required('Listing Agreement is required')
        }),
      lease_working_with_realtor: Yup.mixed()
        .nullable()
        .when(['lease_all_document', 'type'], {
          is: (lease_all_document, type) =>
            (lease_all_document === null || typeof lease_all_document === 'undefined') && type === 'lease',
          then: () => Yup.mixed().required('Working with a Realtor is required')
        }),
      lease_buyer_rep_agreement: Yup.mixed()
        .nullable()
        .when(['lease_all_document', 'type', 'brokerage_side', 'representative_type'], {
          is: (lease_all_document, type, brokerage_side, representative_type) =>
            (lease_all_document === null || typeof lease_all_document === 'undefined') &&
            type === 'lease' &&
            brokerage_side !== 'listing' &&
            representative_type !== 'seller',
          then: () =>
            Yup.mixed().required('Tenant Representation Agreement /Buyer Representation Agreement is required')
        }),
      lease_copy_deposit_receipt: Yup.mixed()
        .nullable()
        .when(['lease_all_document', 'type'], {
          is: (lease_all_document, type) =>
            (lease_all_document === null || typeof lease_all_document === 'undefined') && type === 'lease',
          then: () => Yup.mixed().required('Copy of Deposit Receipt is required')
        }),
      lease_data_sheet: Yup.mixed()
        .nullable()
        .when(['lease_all_document', 'type', 'brokerage_side', 'representative_type'], {
          is: (lease_all_document, type, brokerage_side, representative_type) =>
            (lease_all_document === null || typeof lease_all_document === 'undefined') &&
            type === 'lease' &&
            brokerage_side !== 'co-op' &&
            representative_type !== 'buyer',
          then: () => Yup.mixed().required('Data Sheet is required')
        }),
      lease_additional_document: Yup.mixed().nullable(), 
      */
      brokerage: Yup.string()
        .nullable()
        .when(['type', 'brokerage_side'], {
          is: (type, brokerage_side) => type !== 'pre-con' && brokerage_side !== 'both',
          then: () =>
            Yup.string().max(150, 'Brokerage Name must be atmost 150 Characters').required('Brokerage Name required')
        }),
      brokerage_phone_number: Yup.string()
        .nullable()
        .when(['type', 'brokerage_side'], {
          is: (type, brokerage_side) => type !== 'pre-con' && brokerage_side !== 'both',
          then: () =>
            Yup.string()
              .required('Brokerage phone number is required')
              .transform((value, originalValue) => (originalValue === '' ? undefined : value))
              .matches(phoneRegex, 'Please enter a valid phone number')
        }),
      brokerage_email: Yup.string()
        .nullable()
        .matches(emailRegex, 'Please enter a valid email')
        .transform((value, originalValue) => (originalValue === '' ? undefined : value)),
      deal_agents: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .nullable()
            .when('brokerage_email', {
              is: (brokerage_email) => type !== 'pre-con' && brokerage_side !== 'both',
              then: () =>
                Yup.string().max(50, 'Agent Name must be atmost 50 Characters').required('Agent Name is required')
              // .matches(nameRegex, 'Name cannot contain numbers and special characters')
            }),
          email: Yup.string()
            .nullable()
            .when(['type', 'brokerage_side'], {
              is: (type, brokerage_side) => type !== 'pre-con' && brokerage_side !== 'both',
              then: () =>
                Yup.string()
                  .nullable()
                  .matches(emailRegex, 'Please enter a valid email')
                  .transform((value, originalValue) => (originalValue === '' ? undefined : value))
            }),
          phone: Yup.string()
            .nullable()
            .when(['type', 'brokerage_side'], {
              is: (type, brokerage_side) => type !== 'pre-con' && brokerage_side !== 'both',
              then: () =>
                Yup.string()
                  .nullable()
                  .transform((value, originalValue) => (originalValue === '' ? undefined : value))
                  .matches(phoneRegex, 'Please enter a valid phone number')
            })
        })
      ),
      listing_brokerage_commission: Yup.string()
        .nullable()
        .when(['type', 'brokerage_side', 'representative_type'], {
          is: (type, brokerage_side, representative_type) =>
            type !== 'pre-con' && brokerage_side !== 'co-op' && representative_type !== 'buyer',
          then: () =>
            Yup.string()
              .nullable()
              // .required('Listing Brokerage Commission  is required')
              .test('is-not-zero', 'Listing Brokerage Commission must be greater than 0', (value) => {
                if (!value) return true
                const parsedValue = parseFloat(value)
                return parsedValue !== 0
              })
              .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        }),
      cooperating_brokerage_commission: Yup.string()
        .nullable()
        .when(['type'], {
          is: (type) => type !== 'pre-con',
          then: () =>
            Yup.string()
              .nullable()
              // .required('Cooperating Brokerage Commission  is required')
              .test('is-not-zero', 'Cooperating Brokerage Commission must be greater than 0', (value) => {
                if (!value) return true
                const parsedValue = parseFloat(value)
                return parsedValue !== 0
              })
              .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        }),
      total: Yup.string()
        .nullable()
        .when(['type'], {
          is: (type) => type !== 'pre-con',
          then: () =>
            Yup.string()
              .nullable()
              // .required('Total is required')
              .test('is-not-zero', 'Total must be greater than 0', (value) => {
                if (!value) return true
                const parsedValue = parseFloat(value)
                return parsedValue !== 0
              })
              .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        }),
      first_installment: Yup.mixed()
        .nullable()
        .when('type', {
          is: (type) => {
            return type === 'pre-con'
          },
          then: () =>
            Yup.number()
              .required('First installment is required')
              .min(1, 'First installment must be greater than 0')
              .max(10000000, 'First installment must be less than 10,000,000')
              .transform((value, originalValue) => (isNaN(originalValue) ? undefined : value))
        }),
      second_installment: Yup.mixed()
        .nullable()
        .when('type', {
          is: (type) => type === 'pre-con',
          then: () =>
            Yup.number()
              .nullable()
              .max(10000000, 'Second installment must be less than 10,000,000')
              .transform((value, originalValue) => (isNaN(originalValue) ? undefined : value))
        }),
      third_installment: Yup.mixed()
        .nullable()
        .when('type', {
          is: (type) => type === 'pre-con',
          then: () =>
            Yup.number()
              .nullable()
              .max(10000000, 'Third installment must be less than 10,000,000')
              .transform((value, originalValue) => (isNaN(originalValue) ? undefined : value))
        }),
      final_installment: Yup.mixed()
        .nullable()
        .when('type', {
          is: (type) => type === 'pre-con',
          then: () =>
            Yup.number()
              .nullable()
              .max(10000000, 'Final installment must be less than 10,000,000')
              .transform((value, originalValue) => (isNaN(originalValue) ? undefined : value))
        }),
      referral_office_phone: Yup.string()
        .nullable()
        .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        .matches(phoneRegex, 'Please enter a valid phone number'),
      referral_office_email: Yup.string()
        .matches(emailRegex, 'Please enter a valid email')
        .transform((value, originalValue) => (originalValue === '' ? undefined : value)),
      referral_first_name: Yup.string().max(50, 'Referral First Name must be atmost 50 Characters'),
      // .matches(nameRegex, 'First Name cannot contain numbers and special characters'),
      referral_last_name: Yup.string().max(50, 'Referral Last Name must be atmost 50 Characters'),
      // .matches(nameRegex, 'Last Name cannot contain numbers and special characters'),
      referral_percentage: Yup.number()
        .max(100, 'Referral Percentage must be atmost 100')
        .transform((value, originalValue) => (isNaN(originalValue) ? undefined : value)),
      seller_lawyer_phone: Yup.string().when('type', {
        is: (value) => value !== 'lease',
        then: () =>
          Yup.string()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' ? undefined : value))
            .matches(phoneRegex, 'Please enter a valid phone number')
      }),
      seller_lawyer_email: Yup.string().when('type', {
        is: (value) => value !== 'lease',
        then: () =>
          Yup.string()
            .matches(emailRegex, 'Please enter a valid email')
            .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      }),

      seller_lawyer_name: Yup.string().when('type', {
        is: (value) => value !== 'lease',
        then: () => Yup.string().max(50, 'Name must be atmost 50 Characters')
        // .matches(nameRegex, 'Name cannot contain numbers and special characters')
      }),

      buyer_lawyer_phone: Yup.string().when('type', {
        is: (value) => value !== 'lease',
        then: () =>
          Yup.string()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' ? undefined : value))
            .matches(phoneRegex, 'Please enter a valid phone number')
      }),
      buyer_lawyer_email: Yup.string().when('type', {
        is: (value) => value !== 'lease',
        then: () =>
          Yup.string()
            .matches(emailRegex, 'Please enter a valid email')
            .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      }),
      buyer_lawyer_name: Yup.string().when('type', {
        is: (value) => value !== 'lease',
        then: () => Yup.string().max(50, 'Name must be atmost 50 Characters')
        // .matches(nameRegex, 'Name cannot contain numbers and special characters')
      })
    })
  }

  // Agent Deal Validation
  static validationSchemaAgentDeal = () => {
    return Yup.object().shape({
      brokerage_side: Yup.string()
        .nullable()
        .when(['type'], {
          is: (type) => type !== 'pre-con',
          then: () => Yup.mixed().required('Deal type is required')
        }),
      listing_id: Yup.mixed().when(['brokerage_side', 'representative_type', 'type'], {
        is: (brokerage_side, representative_type, type) =>
          brokerage_side !== 'co-op' && representative_type !== 'buyer' && type !== 'pre-con',
        then: () => Yup.mixed().required('Listing is required')
      }),
      property_address: Yup.string()
        .nullable()
        .when(['brokerage_side', 'representative_type', 'type'], {
          is: (brokerage_side, representative_type, type) =>
            brokerage_side === 'co-op' || representative_type === 'buyer' || type === 'pre-con',
          then: () =>
            Yup.string()
              .max(190, 'Property Address  must be atmost 190 Characters')
              .required('Property address is required')
        }),
      double_ended: Yup.string()
        .nullable()
        .when('brokerage_side', {
          is: (value) => value === 'both',
          then: () => Yup.string().required('Double ended deal is required')
        }),
      representative_type: Yup.string()
        .nullable()
        .when('double_ended', {
          is: (value) => value === 'no',
          then: () => Yup.string().required('Representative type is required')
        }),

      // might need in future
      // sale_all_document: Yup.mixed().nullable(),
      // sale_agreement_purchase_sale: Yup.mixed()
      //   .nullable()
      //   .when(['sale_all_document', 'type'], {
      //     is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
      //     then: () => Yup.mixed().required('Agreement of Purchase and Sale is required')
      //   }),
      // sale_confirmation_cooperating_representative: Yup.mixed()
      //   .nullable()
      //   .when(['sale_all_document', 'type'], {
      //     is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
      //     then: () => Yup.mixed().required('Confirmation of Cooperation is required')
      //   }),
      // sale_working_with_realtor: Yup.mixed()
      //   .nullable()
      //   .when(['sale_all_document', 'type'], {
      //     is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
      //     then: () => Yup.mixed().required('Working with a Realtor is required')
      //   }),
      // sale_fintrac_form_630: Yup.mixed()
      //   .nullable()
      //   .when(['sale_all_document', 'type'], {
      //     is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
      //     then: () =>
      //       Yup.mixed().required('Fintrac Form 630  - Individual Identification Information Record is required')
      //   }),
      // sale_fintrac_form_634: Yup.mixed()
      //   .nullable()
      //   .when(['sale_all_document', 'type'], {
      //     is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
      //     then: () =>
      //       Yup.mixed().required(
      //         'Fintrac Form 634 - Politically Exposed Person/Head of International Organization Checklist is required'
      //       )
      //   }),
      // sale_copy_deposit_receipt: Yup.mixed()
      //   .nullable()
      //   .when(['sale_all_document', 'type'], {
      //     is: (value, brokerage_side) => (typeof value === 'undefined' || value === null) && brokerage_side === 'sale',
      //     then: () => Yup.mixed().required('Copy of Deposit Receipt is required')
      //   }),

      // Condition for required docs for listing and both
      // sale_listing_agreement: Yup.mixed()
      //   .nullable()
      //   .when(['sale_all_document', 'brokerage_side', 'type', 'representative_type'], {
      //     is: (sale_all_document, brokerage_side, type, representative_type) =>
      //       (sale_all_document === null || typeof sale_all_document === 'undefined') &&
      //       brokerage_side !== 'co-op' &&
      //       representative_type !== 'buyer' &&
      //       type === 'sale',
      //     then: () => Yup.mixed().required('Listing Agreement is required')
      //   }),
      // sale_data_sheet: Yup.mixed()
      //   .nullable()
      //   .when(['sale_all_document', 'brokerage_side', 'type', 'representative_type'], {
      //     is: (sale_all_document, brokerage_side, type, representative_type) =>
      //       (sale_all_document === null || typeof sale_all_document === 'undefined') &&
      //       brokerage_side !== 'co-op' &&
      //       representative_type !== 'buyer' &&
      //       type === 'sale',
      //     then: () => Yup.mixed().required('Data Sheet is required')
      //   }),

      // Condition for required docs for co-op and both
      // sale_form_127: Yup.mixed()
      //   .nullable()
      //   .when(['sale_all_document', 'brokerage_side', 'type', 'representative_type'], {
      //     is: (sale_all_document, brokerage_side, type, representative_type) =>
      //       (sale_all_document === null || typeof sale_all_document === 'undefined') &&
      //       brokerage_side !== 'listing' &&
      //       representative_type !== 'seller' &&
      //       type === 'sale',
      //     then: () => Yup.mixed().required('Form 127 – Condition(s) In Offer – Buyer Acknowledgment is required')
      //   }),
      // sale_fintrac_form_635: Yup.mixed()
      //   .nullable()
      //   .when(['sale_all_document', 'brokerage_side', 'type', 'representative_type'], {
      //     is: (sale_all_document, brokerage_side, type, representative_type) =>
      //       (sale_all_document === null || typeof sale_all_document === 'undefined') &&
      //       brokerage_side !== 'listing' &&
      //       representative_type !== 'seller' &&
      //       type === 'sale',
      //     then: () => Yup.mixed().required('Fintrac Form 635 – Receipt of Funds is required')
      //   }),
      // sale_buyer_rep_agreement: Yup.mixed()
      //   .nullable()
      //   .when(['sale_all_document', 'brokerage_side', 'type', 'representative_type'], {
      //     is: (sale_all_document, brokerage_side, type, representative_type) =>
      //       (sale_all_document === null || typeof sale_all_document === 'undefined') &&
      //       brokerage_side !== 'listing' &&
      //       representative_type !== 'seller' &&
      //       type === 'sale',
      //     then: () => Yup.mixed().required('Buyer Representation Agreement is required')
      //   }),

      //Pre-con deal documents validation
      // new_sale_all_document: Yup.mixed().nullable(),
      // new_sale_first_page_agreement_purchase: Yup.mixed()
      //   .nullable()
      //   .when(['new_sale_all_document', 'type'], {
      //     is: (new_sale_all_document, type) =>
      //       (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
      //     then: () => Yup.mixed().required('1st page of Agreement of Purchase and Sale and all amendments is required')
      //   }),
      // new_sale_broker_coop_letter: Yup.mixed()
      //   .nullable()
      //   .when(['new_sale_all_document', 'type'], {
      //     is: (new_sale_all_document, type) =>
      //       (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
      //     then: () => Yup.mixed().required('Broker Co-op Letter is required')
      //   }),
      // new_sale_first_page_tarion: Yup.mixed()
      //   .nullable()
      //   .when(['new_sale_all_document', 'type'], {
      //     is: (new_sale_all_document, type) =>
      //       (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
      //     then: () => Yup.mixed().required('1st page of Tarion is required')
      //   }),
      // new_sale_working_with_realtor: Yup.mixed()
      //   .nullable()
      //   .when(['new_sale_all_document', 'type'], {
      //     is: (new_sale_all_document, type) =>
      //       (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
      //     then: () => Yup.mixed().required('Working with a Realtor is required')
      //   }),
      // new_sale_buyer_rep_agreement: Yup.mixed()
      //   .nullable()
      //   .when(['new_sale_all_document', 'type'], {
      //     is: (new_sale_all_document, type) =>
      //       (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
      //     then: () => Yup.mixed().required('Buyer Representation Agreement is required')
      //   }),
      // mortgage_preapproval: Yup.mixed()
      //   .nullable()
      //   .when(['new_sale_all_document', 'type'], {
      //     is: (new_sale_all_document, type) =>
      //       (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
      //     then: () => Yup.mixed().required('Mortgage Pre-Approvals is required')
      //   }),
      // new_sale_copy_deposit_receipt: Yup.mixed()
      //   .nullable()
      //   .when(['new_sale_all_document', 'type'], {
      //     is: (new_sale_all_document, type) =>
      //       (new_sale_all_document === null || typeof new_sale_all_document === 'undefined') && type === 'pre-con',
      //     then: () => Yup.mixed().required('Copy of Deposits is required')
      //   }),

      //Lease deal documents validation
      // lease_all_document: Yup.mixed().nullable(),
      // lease_agreement_lease: Yup.mixed()
      //   .nullable()
      //   .when(['lease_all_document', 'type'], {
      //     is: (lease_all_document, type) =>
      //       (lease_all_document === null || typeof lease_all_document === 'undefined') && type === 'lease',
      //     then: () => Yup.mixed().required('Agreement to Lease is required')
      //   }),
      // lease_ontario_standard_lease: Yup.mixed()
      //   .nullable()
      //   .when(['lease_all_document', 'type'], {
      //     is: (lease_all_document, type) =>
      //       (lease_all_document === null || typeof lease_all_document === 'undefined') && type === 'lease',
      //     then: () => Yup.mixed().required('Ontario Standard Lease Agreement is required')
      //   }),
      // lease_confirmation_cooperating_representative: Yup.mixed()
      //   .nullable()
      //   .when(['lease_all_document', 'type'], {
      //     is: (lease_all_document, type) =>
      //       (lease_all_document === null || typeof lease_all_document === 'undefined') && type === 'lease',
      //     then: () => Yup.mixed().required('Confirmation of Cooperation is required')
      //   }),
      // lease_listing_agreement: Yup.mixed()
      //   .nullable()
      //   .when(['lease_all_document', 'type', 'brokerage_side', 'representative_type'], {
      //     is: (lease_all_document, type, brokerage_side, representative_type) =>
      //       (lease_all_document === null || typeof lease_all_document === 'undefined') &&
      //       type === 'lease' &&
      //       brokerage_side !== 'co-op' &&
      //       representative_type !== 'buyer',
      //     then: () => Yup.mixed().required('Listing Agreement is required')
      //   }),
      // lease_working_with_realtor: Yup.mixed()
      //   .nullable()
      //   .when(['lease_all_document', 'type'], {
      //     is: (lease_all_document, type) =>
      //       (lease_all_document === null || typeof lease_all_document === 'undefined') && type === 'lease',
      //     then: () => Yup.mixed().required('Working with a Realtor is required')
      //   }),
      // lease_buyer_rep_agreement: Yup.mixed()
      //   .nullable()
      //   .when(['lease_all_document', 'type', 'brokerage_side', 'representative_type'], {
      //     is: (lease_all_document, type, brokerage_side, representative_type) =>
      //       (lease_all_document === null || typeof lease_all_document === 'undefined') &&
      //       type === 'lease' &&
      //       brokerage_side !== 'listing' &&
      //       representative_type !== 'seller',
      //     then: () =>
      //       Yup.mixed().required('Tenant Representation Agreement /Buyer Representation Agreement is required')
      //   }),
      // lease_copy_deposit_receipt: Yup.mixed()
      //   .nullable()
      //   .when(['lease_all_document', 'type'], {
      //     is: (lease_all_document, type) =>
      //       (lease_all_document === null || typeof lease_all_document === 'undefined') && type === 'lease',
      //     then: () => Yup.mixed().required('Copy of Deposit Receipt is required')
      //   }),
      // lease_data_sheet: Yup.mixed()
      //   .nullable()
      //   .when(['lease_all_document', 'type', 'brokerage_side', 'representative_type'], {
      //     is: (lease_all_document, type, brokerage_side, representative_type) =>
      //       (lease_all_document === null || typeof lease_all_document === 'undefined') &&
      //       type === 'lease' &&
      //       brokerage_side !== 'co-op' &&
      //       representative_type !== 'buyer',
      //     then: () => Yup.mixed().required('Data Sheet is required')
      //   }),
      // lease_additional_document: Yup.mixed().nullable(),

      first_installment: Yup.string()
        .nullable()
        .when('type', {
          is: (value) => value === 'pre-con',
          then: () =>
            Yup.number()
              .required('First installment is required')
              .min(1, 'First installment must be greater than 0')
              .max(10000000, 'First installment must be less than 10,000,000')
              .transform((value, originalValue) => (isNaN(originalValue) ? undefined : value))
        }),
      second_installment: Yup.string()
        .nullable()
        .when('type', {
          is: (value) => value === 'pre-con',
          then: () =>
            Yup.number()
              .nullable()
              .max(10000000, 'Second installment must be less than 10,000,000')
              .transform((value, originalValue) => (isNaN(originalValue) ? undefined : value))
        }),
      third_installment: Yup.string()
        .nullable()
        .when('type', {
          is: (value) => value === 'pre-con',
          then: () =>
            Yup.number()
              .nullable()
              .max(10000000, 'Third installment must be less than 10,000,000')
              .transform((value, originalValue) => (isNaN(originalValue) ? undefined : value))
        }),
      final_installment: Yup.string()
        .nullable()
        .when('type', {
          is: (value) => value === 'pre-con',
          then: () =>
            Yup.number()
              .nullable()
              .max(10000000, 'Final installment must be less than 10,000,000')
              .transform((value, originalValue) => (isNaN(originalValue) ? undefined : value))
        }),
      listing_brokerage_commission: Yup.string()
        .nullable()
        .when(['type', 'brokerage_side', 'representative_type'], {
          is: (type, brokerage_side, representative_type) =>
            type !== 'pre-con' && brokerage_side !== 'co-op' && representative_type !== 'buyer',
          then: () =>
            Yup.string()
              .nullable()
              // .required('Listing Brokerage Commission is required')
              .test('is-not-zero', 'Listing Brokerage Commission must be greater than 0', (value) => {
                if (!value) return true
                const parsedValue = parseFloat(value)
                return parsedValue !== 0
              })
              .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        }),
      cooperating_brokerage_commission: Yup.string()
        .nullable()
        .when(['type'], {
          is: (type) => type !== 'pre-con',
          then: () =>
            Yup.string()
              .nullable()
              // .required('Cooperating Brokerage Commission  is required')
              .test('is-not-zero', 'Cooperating Brokerage Commission must be greater than 0', (value) => {
                if (!value) return true
                const parsedValue = parseFloat(value)
                return parsedValue !== 0
              })
              .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        }),
      total: Yup.string()
        .nullable()
        .when(['type'], {
          is: (type) => type !== 'pre-con',
          then: () =>
            Yup.string()
              .nullable()
              // .required('Total is required')
              .test('is-not-zero', 'Total must be greater than 0', (value) => {
                if (!value) return true
                const parsedValue = parseFloat(value)
                return parsedValue !== 0
              })
              .transform((value, originalValue) => (originalValue === '' ? undefined : value))
        })
    })
  }
}
